<template>
  <div class="page page-project">
    <div class="fit v-px-md">
      <a-tabs type="card" v-model="tab" @change="handleTabChange">
        <a-tab-pane key="unchecked" tab="未提交"></a-tab-pane>
        <a-tab-pane key="checked" tab="已提交"></a-tab-pane>
      </a-tabs>
      <!-- <router-view></router-view> -->
      <compontent :is="currentTabComponent" />
    </div>
  </div>
</template>

<script>
import DocumentStarted from './checked'
import DocumentUnstarted from './unchecked'
export default {
  namne: 'Project',
  data() {
    return {
      tab: undefined,
      currentTabComponent: null,
    }
  },
  watch: {
    $route() {
      this.tab = this.$route.query.tab || 'started'
    },
    tab(key) {
      if (key === 'checked') {
        this.currentTabComponent = DocumentStarted
      } else if (key === 'unchecked') {
        this.currentTabComponent = DocumentUnstarted
      }
    },
  },
  methods: {
    initTabKey() {
      let { path } = this.$route
      this.tab = path.substring(path.lastIndexOf('/') + 1)
    },
    handleTabChange(key) {
      this.$router.push({ path: 'list', query: { tab: key } })
    },
    handleMenuClick(key) {
      console.log(key)
    },
  },
  mounted() {
    this.tab = this.$route.query.tab || 'unchecked'
  },
}
</script>
