<template>
  <div class="page page-roadshow-list" s>
    <a-spin :spinning="loading">
      <div class="list-table-container" style="min-height: 200px" v-if="list.length">
        <div class="list-toolbar bg-orange-1 flex items-center v-mb-xs">
          <div class="list-toolbar-extra">
            共
            <b class="text-indigo font-special">{{ list.length }}</b>
            个项目，合计
            <b class="text-red font-special">{{ total }}</b>
            份文档未提交
          </div>
          <span class="hover-line text-indigo v-ml-auto underline" @click="load">
            <!-- <a-icon type="sync" class="v-mr-xs" /> -->
            刷新
          </span>
        </div>
        <div class="project-item" v-for="item in list" :key="item.id">
          <div class="project-item-title flex items-center">
            <router-link :to="{ path: '/usercenter/project/view', query: { id: item.id } }" :title="item.projectName">{{ item.projectName }}</router-link>
            <a-tag class="tag-tiny" color="#e10" v-if="item.state === 5">资助执行中</a-tag>
            <a-tag class="tag-tiny" color="#e10" v-else-if="item.state === 6">项目结项中</a-tag>
            <!-- <div class="v-ml-auto">
            申请人：
            <span v-if="item.applicant">{{ item.applicant.realName }}</span>
          </div> -->
          </div>
          <div class="project-item-content">
            <a-table class="list-table" size="small" :bordered="false" :pagination="false" :columns="reportCols" :data-source="item.reports" :scroll="{ x: '100%' }" rowKey="id">
              <template slot="action" slot-scope="text, record">
                <span class="text-indigo font-xs hover-line" title="保存" @click="handleSaveReport(record, index)" v-if="record.state === 1">审核</span>
              </template>
              <template slot="reportDirection" slot-scope="text, record">
                <span v-if="record.reportDirection === 1">{{ item.applicant ? item.applicant.realName : '' }}</span>
                <span v-else-if="record.reportDirection === 2">千禾基金</span>
              </template>
              <template slot="rest" slot-scope="text, record">
                <span class="text-red" v-if="record.restDays < 0">
                  逾期
                  <b>{{ -record.restDays }}</b>
                  天
                </span>
                <span class="text-red" v-else-if="record.restDays == 0">明日逾期</span>
                <span v-else-if="record.restDays > 0">
                  剩余
                  <b class="text-indigo">{{ record.restDays }}</b>
                  天
                </span>
              </template>
              <template slot="state" slot-scope="text, record">
                <span class="text-grey" v-if="record.state === 0">未上传</span>
                <span class="text-red" v-else-if="record.state === 1">已上传待审核</span>
                <span class="text-red" v-else-if="record.state === -1">未通过审核</span>
                <!-- <span class="text-red" v-else-if="record.state === -1">审核未通过</span> -->
              </template>
              <template slot="action" slot-scope="text, record">
                <span class="text-indigo hover-line" @click="showReportModal(item, record)" v-if="record.state === 0">上传</span>
                <span class="text-indigo hover-line" @click="showReportModal(item, record)" v-else-if="record.state === 1">查看</span>
                <span class="text-indigo hover-line" @click="showReportModal(item, record)" v-else-if="record.state === -1">重新上传</span>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <div style="padding: 60px 0" v-else>
        <a-empty :image="simpleImage"></a-empty>
      </div>
    </a-spin>
    <a-modal :confirmLoading="reportUploading" :visible="reportModalVisible" :width="reportModalData.state !== 1 ? 500 : 500" @cancel="cancelReportModal" centered title="上传文档">
      <template slot="footer">
        <a-space v-if="reportModalData.state !== 1">
          <a-button icon="close" @click="cancelReportModal">取消</a-button>
          <a-button type="primary" icon="check" @click="handleUploadReport" :loading="reportUploading">提交</a-button>
        </a-space>
        <a-button icon="close" @click="cancelReportModal" v-else>关闭</a-button>
      </template>
      <a-alert class="v-mb-sm" type="error" v-if="reportModalData.state === -1">
        <div slot="message" class="text-red">上次上传文档已被驳回，审核意见：{{ reportModalData.checkOpinion }}</div>
      </a-alert>
      <a-form autocomplete="off" class="form-dense" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }" v-if="reportModalData.state === 1">
        <a-form-item>
          <span slot="label">项目名称</span>
          <span>{{ reportModalData.projectName }}</span>
        </a-form-item>
        <a-form-item>
          <span slot="label">文档名称</span>
          <span>{{ reportModalData.reportName }}</span>
        </a-form-item>
        <a-form-item>
          <span slot="label">截止日期</span>
          <span>{{ reportModalData.limitDate }}</span>
        </a-form-item>
        <a-form-item>
          <span slot="label">上传日期</span>
          <span>{{ reportModalData.submitDate }}</span>
        </a-form-item>
        <a-form-item>
          <span slot="label">已上传文件</span>
          <file-container :files="reportModalData.files" :belongId="reportModalData.projectId" fileSign="P01" :imgSize="64" v-if="reportModalData.files && reportModalData.projectId"></file-container>
          <!-- <div class="file-list" v-if="reportModalData.files">
            <div class="file-item" v-for="file in reportModalData.files" :key="file.id">
              <span class="text-indigo hover-line" :title="file.fileOriginName">{{ file.fileOriginName }}</span>
            </div>
          </div> -->
        </a-form-item>
      </a-form>
      <a-form autocomplete="off" class="form-dense" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }" v-else>
        <a-form-item>
          <span slot="label">项目名称</span>
          <span>{{ reportModalData.projectName }}</span>
        </a-form-item>
        <a-form-item>
          <span slot="label">文档名称</span>
          <span>{{ reportModalData.reportName }}</span>
        </a-form-item>
        <a-form-item>
          <span slot="label">截止日期</span>
          <span>{{ reportModalData.limitDate }}</span>
        </a-form-item>
        <a-form-item v-if="reportModalData.state === -1 && reportModalData.files.filter(item => item.state === 3).length">
          <span slot="label">原上传文件</span>
          <file-container :files="reportModalData.files.filter(item => item.state === 3)" :belongId="reportModalData.projectId" fileSign="P01" :imgSize="64" v-if="reportModalData.projectId"></file-container>
        </a-form-item>
        <a-form-item>
          <span slot="label">
            <i class="required-mark">*</i>
            上传文件
          </span>
          <a-upload-dragger
            :action="`${baseURL}/file/upload`"
            :data="{
              params: JSON.stringify({ fileBelongId: reportModalData.projectId, fileType: '204', fileSign: 'P01' }),
            }"
            :show-upload-list="{
              showPreviewIcon: true,
              showRemoveIcon: true,
              showDownloadIcon: true,
            }"
            :file-list="reportFiles"
            :before-upload="beforeUploadFile"
            @change="handleUploadReportFile"
            @preview="handlePreviewFile"
            @download="({ fileId, name }) => downloadFile({ fileId, belongId: reportModalData.projectId, fileSign: 'P01', fileName: name })"
            accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .pdf, .rar, .zip, .ppt, .pptx, .xls, .xlsx"
          >
            <a-icon type="plus" />
            <p class="text-faded">添加文件</p>
          </a-upload-dragger>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
// import { Empty } from 'ant-design-vue'
import { getTodoReports, uploadReport } from '@/api/main'
export default {
  name: 'ProjectStarted',
  data() {
    return {
      // simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      loading: false,
      list: [],
      reportCols: [
        {
          title: '#',
          dataIndex: 'id',
          width: 50,
          align: 'center',
          customRender: (text, record, index) => index + 1,
        },
        {
          title: '文件类型',
          ellipsis: true,
          dataIndex: 'reportName',
          // scopedSlots: { customRender: 'rsName' },
        },
        {
          title: '提交人',
          ellipsis: true,
          dataIndex: 'reportDirection',
          // width: 150,
          scopedSlots: { customRender: 'reportDirection' },
        },
        {
          title: '提交截止日期',
          dataIndex: 'limitDate',
          align: 'center',
          width: 100,
        },
        {
          title: '剩余期限',
          dataIndex: 'rest',
          width: 160,
          align: 'center',
          scopedSlots: { customRender: 'rest' },
        },
        {
          title: '文档状态',
          dataIndex: 'state',
          align: 'center',
          width: 110,
          scopedSlots: { customRender: 'state' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 80,
          scopedSlots: { customRender: 'action' },
        },
      ],
      total: 0,
      reportFiles: [],
      reportUploading: false,
      reportModalVisible: false,
      reportModalData: {},
    }
  },
  methods: {
    load() {
      this.loading = true
      getTodoReports()
        .then(({ data }) => {
          if (data.success) {
            console.log(data.result)
            this.list = data.result
            this.total = this.list.map(item => item.reports.length).reduce((a, b) => a + b)
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
      /*
      getProjects(params)
        .then(({ data }) => {
          if (data.success) {
            let { records } = data.result
            records.forEach(item => {
              let { reports, period } = item
              let checkedReports = reports.filter(report => report.state === 2)
              let uncheckReports = reports.filter(report => report.state !== 2 && report.reportDirection === 1)
              item.checkedReports = checkedReports.length
              item.uncheckReports = uncheckReports.length
              item.overReports = uncheckReports.filter(report => report.restDays).length
              if (period) {
                item.months = Math.round(period / 30)
              }
              uncheckReports.sort((a, b) => a.state - b.state || a.restDays - b.restDays)
              item.reports = uncheckReports
            })
            this.list = records.filter(item => item.uncheckReports > 0)
            this.total = this.list.map(item => item.uncheckReports).reduce((a, b) => a + b)
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
        */
    },
    handleUploadReportFile(info) {
      this.reportFiles = this.handleUploadFile(info, -1)
    },
    showReportModal(item, data) {
      this.reportModalData = { projectName: item.projectName, ...data }
      this.reportFiles = []
      this.reportModalVisible = true
    },
    cancelReportModal() {
      this.reportModalVisible = false
      this.reportFiles = []
    },
    handleUploadReport() {
      let fileIds = this.reportFiles.map(file => file.fileId)
      this.$message.destroy()
      if (fileIds.length === 0) {
        this.$message.warning('前添加上传文件')
        return
      }
      let { id, projectId } = this.reportModalData
      this.reportUploading == true
      let params = {
        id,
        projectId,
        fileIds: fileIds.join(','),
      }
      uploadReport(params)
        .then(({ data }) => {
          if (data.success) {
            this.$message.success('提交成功')
            this.cancelReportModal()
            this.load()
          }
          this.reportUploading = false
        })
        .catch(() => {
          this.reportUploading = false
        })
    },
  },
  created() {
    this.load()
  },
}
</script>
