<template>
  <div class="page page-roadshow-list">
    <div class="list-options">
      <!--  :labelCol="{ span: 10 }" :wrapperCol="{ span: 10 }"  -->
      <a-form layout="inline" class="options-form">
        <a-row :gutter="24">
          <a-col span="8">
            <a-form-item :colon="false" label="文件名称"><a-input placeholder="文件类型/文件名" allow-clear v-model="form.reportName" size="default" style="width: 200px" /></a-form-item>
          </a-col>
          <a-col span="8">
            <a-form-item :colon="false" label="项目名称"><a-input placeholder="项目名称" allow-clear v-model="form.projectName" size="default" style="width: 200px" /></a-form-item>
          </a-col>
          <!-- <a-col span="8">
            <a-form-item :colon="false" label="文件类型">
              <a-select placeholder="请选择" v-model="form.fileFormat" size="default" style="width: 200px" allowClear>
                <a-select-option value="pdf">pdf</a-select-option>
                <a-select-option value="doc">doc</a-select-option>
                <a-select-option value="excel">excel</a-select-option>
                <a-select-option value="图片">图片</a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
        </a-row>
      </a-form>
    </div>
    <div class="flex items-center v-my-sm">
      <div class="v-ml-auto">
        <a-space>
          <a-button type="primary" icon="search" size="small" :loading="loading" @click="load">查询</a-button>
        </a-space>
      </div>
    </div>
    <div class="list-table-container">
      <div class="list-toolbar flex items-center">
        <div class="list-toolbar-extra">
          共
          <span class="text-primary font-special">{{ pagination.total }}</span>
          条记录
        </div>
        <div class="list-pagination v-ml-auto">
          <a-pagination size="small" :current="pagination.pageNum" :total="pagination.total" :pageSize="pagination.pageSize" show-size-changer show-quick-jumper @change="onPageChange" @showSizeChange="onPageChange" />
        </div>
      </div>
      <a-table class="list-table" size="small" :bordered="false" :pagination="false" :loading="loading" :columns="reportCols" :data-source="list" :scroll="{ x: '100%' }" rowKey="id">
        <template slot="reportDirection" slot-scope="text, record">
          <span v-if="record.reportDirection === 1">{{ record.project.applicant ? record.project.applicant.realName : '' }}</span>
          <span v-else-if="record.reportDirection === 2">千禾基金</span>
        </template>
        <template slot="project" slot-scope="text, record">
          <router-link class="text-indigo" :title="text" :to="{ path: '/usercenter/project/view', query: { id: record.project.id } }">{{ record.project && record.project.projectName }}</router-link>
        </template>
        <template slot="files" slot-scope="text, record">
          <file-container :files="record.files" :belongId="record.project.id" fileSign="P01" v-if="record.files && record.project"></file-container>
          <!-- <div class="file-list" v-if="record.files">
            <div class="file-item" v-for="file in record.files" :key="file.id">
              <img class="file-img" src="~@/assets/images/file_word.svg" v-if="['doc', 'docx', 'wps'].includes(file.fileFormat)" />
              <img class="file-img" src="~@/assets/images/file_excel.svg" v-else-if="file.fileFormat === 'xls' || file.fileFormat === 'xlsx'" />
              <img class="file-img" src="~@/assets/images/file_ppt.svg" v-else-if="file.fileFormat === 'ppt' || file.fileFormat === 'pptx'" />
              <img class="file-img" src="~@/assets/images/file_compress.svg" v-else-if="['rar', 'zip', '7z'].includes(file.fileFormat)" />
              <img class="file-img" src="~@/assets/images/file_img.svg" v-else-if="['jpg', 'jpeg', 'png', 'gif'].includes(file.fileFormat)" />
              <img class="file-img" src="~@/assets/images/file_txt.svg" v-else-if="file.fileFormat === 'txt'" />
              <img class="file-img" src="~@/assets/images/file_pdf.svg" v-else-if="file.fileFormat === 'pdf'" />
              <img class="file-img" src="~@/assets/images/file_video.svg" v-else-if="['mp4', 'wmv', 'rmvb', 'avi', 'mov', 'mkv'].includes(file.fileFormat)" />
              <img class="file-img" src="~@/assets/images/file_default.svg" v-else />
              <span class="text-indigo hover-line" @click="downloadFile({ fileId: file.id, belongId: record.project.id, fileSign: 'P01', fileName: file.fileOriginName })">{{ file.fileOriginName }}</span>
            </div>
          </div> -->
        </template>
      </a-table>
      <div class="list-toolbar flex items-center">
        <div class="list-toolbar-extra">
          共
          <span class="text-primary font-special">{{ pagination.total }}</span>
          条记录
        </div>
        <div class="list-pagination v-ml-auto">
          <a-pagination size="small" :current="pagination.pageNum" :total="pagination.total" :pageSize="pagination.pageSize" show-size-changer show-quick-jumper @change="onPageChange" @showSizeChange="onPageChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCheckedReports } from '@/api/main'
export default {
  name: 'ProjectStarted',
  data() {
    return {
      loading: false,
      list: [],
      reportCols: [
        {
          title: '#',
          dataIndex: 'id',
          width: 50,
          align: 'center',
          customRender: (text, record, index) => index + 1,
        },
        {
          title: '项目名称',
          ellipsis: true,
          dataIndex: 'projectName',
          scopedSlots: { customRender: 'project' },
        },
        {
          title: '文档类型',
          ellipsis: true,
          dataIndex: 'reportName',
          // scopedSlots: { customRender: 'rsName' },
        },
        {
          title: '提交人',
          ellipsis: true,
          dataIndex: 'reportDirection',
          width: 150,
          scopedSlots: { customRender: 'reportDirection' },
        },
        {
          title: '截止日期',
          dataIndex: 'limitDate',
          align: 'center',
          width: 100,
        },
        {
          title: '上传日期',
          dataIndex: 'submitDate',
          width: 100,
          align: 'center',
        },
        {
          title: '文件',
          dataIndex: 'files',
          ellipsis: true,
          width: 300,
          scopedSlots: { customRender: 'files' },
        },
      ],
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      form: {
        projectName: '',
        reportName: '',
        fileFormat: undefined,
      },
    }
  },
  methods: {
    load(pageNum = 1, pageSize = 10, sorter) {
      this.loading = true
      let params = {
        pageNum,
        pageSize,
        sorter,
        direction: 1,
        ...this.form,
      }
      getCheckedReports(params)
        .then(({ data }) => {
          if (data.success) {
            let { current, size, total, records } = data.result
            this.list = records
            this.pagination = {
              pageNum: current,
              pageSize: size,
              total,
            }
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    onPageChange(pageNum, pageSize) {
      this.load(pageNum, pageSize)
    },
  },
  created() {
    this.load()
  },
}
</script>
